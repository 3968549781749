import "../../components/base.css";
import Axios from "axios";
import Layout from "../../components/layout";
import React from "react";
// import styled from '@emotion/styled'
// const ericDildoTitle = styled.div`
// color: blue;
// font-weight: 600;
// `

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      platformStatus: "Checking",
      persistentUniverseStatus: "Checking",
      electronicAccessStatus: "Checking",
    };
  }
  componentDidMount() {
    Axios.get('/.netlify/functions/get-system-status', {
    }).then((response) => {
   const {platformStatus, persistentUniverseStatus, electronicAccessStatus} = response.data;
   this.setState(
     {platformStatus:platformStatus, persistentUniverseStatus:persistentUniverseStatus, electronicAccessStatus:electronicAccessStatus},
     () => {},
   );
 });
 }
    render() {
      return (
        <Layout>
          <div>
            <br></br>
            <h1>&gt; RSI OFFICIAL LINKS</h1>
            <br></br>
              <h2>CURRENT GAME STATUS /</h2>
              <h3>Detailed status <a rel="noreferrer" target="_blank" href="https://status.robertsspaceindustries.com/">page</a></h3>
                <div><h4>Platform: {this.state.platformStatus}</h4></div>
                <div><h4>Persistent Universe: {this.state.persistentUniverseStatus}</h4></div>
                <div><h4>Electronic Access: {this.state.electronicAccessStatus}</h4></div>
                
            <br></br>
          <div>
            <h2>SYSTEM TELEMETRY /</h2>
            <h3>Detailed performance <a rel="noreferrer" target="_blank" href="https://robertsspaceindustries.com/telemetry">page</a></h3>
            <h4>Your computer systems' performance statistics</h4>
            </div>
          <br></br>
          <div>
            <h2>SHIP AVAILABILITY /</h2>
            <h3>Ship pledge <a rel="noreferrer" target="_blank" href="https://robertsspaceindustries.com/pledge/extras?product_id=72">store</a></h3>
            <h4>Live list of ships available to purchase, or upgrade to</h4>
            </div>
          <br></br>
          <div>
            <h2>LOANER MATRIX / </h2>
            <h3>Current ship loaner <a rel="noreferrer" target="_blank" href="https://support.robertsspaceindustries.com/hc/en-us/articles/360003093114-Loaner-Ship-Matrix">matrix</a></h3>
            <h4>List of ships that are loaned for non-flyable ship purchases</h4>
            </div>
          <br></br>
          <div>
            <h2>SOCIAL AND CHAT /</h2>
            <h3>Spectrum <a rel="noreferrer" target="_blank" href="https://robertsspaceindustries.com/spectrum/community/SC">link</a></h3>
            <h4>Spectre, RSI Forums</h4>
            </div>
          </div>
        </Layout>
      );
    }
  }


 